<!doctype html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=0.6" />
    <title>SpeedFine AI</title>
    <meta name="description" content="SpeedFine AI - Estimate and monitor your speed fines with accuracy and ease. Get detailed speed analysis and history with our advanced tools." />
    <meta name="keywords" content="speed, speed estimation, speed monitoring, speed history, speed analysis">
    <meta name="author" content="Vladimir Kozak">
    <meta property="og:title" content="SpeedFine AI - Accurate Speed Fine Estimation & Top Violent Drivers Leaderboard">
    <meta property="og:description" content="Accurately estimate and monitor your speed fines with ease. Discover the leaderboard of top violent drivers and analyze detailed speed histories with SpeedFine AI.">
    <meta property="og:image" content="https://speedfine.com/src/assets/Logo.jpg">
    <meta property="og:url" content="https://speedfine.com">
    <meta property="og:type" content="website">

    <!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-QCENZBTL4Q"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-QCENZBTL4Q');
    </script>

    <script type="module">
      import getImageUrl from '~/shared/utils/get-image-url'; // Adjust the path as needed

      function addFavicon() {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/x-icon';
        link.href = getImageUrl('favicon.ico');
        document.head.appendChild(link);
      }

      document.addEventListener('DOMContentLoaded', addFavicon);
    </script>
  </head>
  <body>
    <div id="root"></div>
    <script type="module" src="/src/index.tsx"></script>
  </body>
</html>
